<!--会员订购-->
<template>
    <div class="MemberOrder">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form
                @submit.native.prevent
                :inline="true"
                style="text-align: right"
                v-model="form"
                size="small"
                label-width="100px"
            >
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="单据号">
                            <el-input placeholder="" v-model="order.code" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="商品名称/条码">
                            <el-input placeholder="" v-model="order.search" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="领取数量">
                            <el-select placeholder="请选择" v-model="order.type">
                                <el-option label="请选择" value="" />
                                <el-option label="可领取" value="1" />
                                <el-option label="已领完" value="2" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="机构名称">
                            <el-input placeholder="" v-model="form.deptName" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="开始日期">
                            <el-date-picker
                                type="date"
                                placeholder="选择日期"
                                v-model="order.startTime"
                                value-format="yyyy-MM-dd"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="结束日期">
                            <el-date-picker
                                type="date"
                                v-model="order.endTime"
                                value-format="yyyy-MM-dd"
                                placeholder="选择日期"
                            />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button type="primary" @click="handleQuery" size="small">查询</el-button>
            <el-button type="primary" @click="handleCreate" size="small">新建</el-button>
            <el-button type="primary" @click="handleExport" size="small">导出</el-button>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                border
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="520"
            >
                <el-table-column label="全选" type="selection" width="60" align="center" fixed="left">
                    <!--                    type='selection'复选框 -->
                </el-table-column>
                <el-table-column label="序号" width="60" type="index" fixed="left" />

                <el-table-column label="订购单号" width="240" prop="code" />
                <el-table-column label="订单名称" width="160" prop="orderName" />
                <el-table-column label="商品" width="160" prop="sku.name" />
                <el-table-column label="商品条码" width="160" prop="sku.bars" />
                <el-table-column label="商品类型" width="160" prop="sku.type">
                    <template slot-scope="scope"
                        ><span>{{ scope.row.sku.type | type }}</span></template
                    >
                </el-table-column>
                <el-table-column label="订购价" width="120">
                    <template slot-scope="scope"
                        ><span>{{ scope.row.price | money }}</span></template
                    >
                </el-table-column>
                <el-table-column label="订购数量" width="160" prop="reserveCount" />
                <el-table-column label="预存金" width="160" prop="reservePrice" />
                <el-table-column label="剩余数量" width="160" prop="surplusCount" />
                <el-table-column label="剩余预存金" width="160" prop="surplusPrice" />
                <el-table-column label="领取数量" width="160" prop="receiveCount" />
                <el-table-column label="退订数量" width="160" prop="refundCount" />
                <el-table-column label="订购时间" width="160" prop="createTime" />
                <el-table-column label="操作" min-width="280" header-align="center">
                    <template slot-scope="scope">
                        <el-button size="mini" type="danger" @click="handleRefund(scope.row)">退订</el-button>
                        <el-button size="mini" type="danger" @click="handleReceive(scope.row)">领取</el-button>
                        <el-button size="mini" type="danger" @click="handleHistory(scope.row)">历史</el-button>
                        <el-button size="mini" type="danger" @click="handleDelete(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :page-sizes="page.pageSizes"
                :page-size="form.limit"
                :layout="page.PageStyle"
                :total="page.total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                @prev-click="handlePrevClick"
                @next-click="handleNextClick"
            />
        </el-card>
        <el-dialog title="变更历史" :visible.sync="dialogTableVisible">
            <el-table
                border
                stripe
                style="width: 100%"
                :data="historyData"
                size="mini"
                :highlight-current-row="true"
                max-height="500"
            >
                <el-table-column label="序号" type="index" align="center" fixed="left" />
                <el-table-column property="beforeChangeCount" label="变化前数量" width="150" />
                <el-table-column property="count" label="领取数量" width="150" />
                <el-table-column property="name" label="退订数量" width="150" />
                <el-table-column property="resultCount" label="剩余数量" width="150" />
                <el-table-column property="changeReservePrice" label="变化前预存金额" width="150" />
                <el-table-column property="consumePrice" label="消费金额" width="150" />
                <el-table-column property="brand" label="退定金额" width="150" />
                <el-table-column property="surplusReservePrice" label="剩余预存金额" width="150" />
                <el-table-column property="updateTime" label="操作时间" width="150" />
            </el-table>
            <el-pagination
                :page-sizes="page.pageSizes"
                :page-size="form.limit"
                :layout="page.PageStyle"
                :total="page.total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                @prev-click="handlePrevClick"
                @next-click="handleNextClick"
            />
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogTableVisible = false" size="small">取 消</el-button>
            </div>
        </el-dialog>

        <el-dialog title="退订" :visible.sync="dialogTableRefund">
            <el-form @submit.native.prevent :inline="true" style="text-align: right" size="small">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="剩余数量">
                            <el-input placeholder="" v-model="refund.surplusCount" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="退订数量">
                            <el-input placeholder="" v-model="refund.count" @change="refundMoney" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="退订金额">
                            <el-input placeholder="" v-model="refund.money" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="handleRefundOk" size="small">确定</el-button>
                <el-button @click="dialogTableRefund = false" size="small">取消</el-button>
            </div>
        </el-dialog>

        <el-dialog title="领取" :visible.sync="dialogTableReceive">
            <el-form @submit.native.prevent :inline="true" style="text-align: right" size="small">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="剩余数量">
                            <el-input placeholder="" v-model="receive.surplusCount" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="领取数量">
                            <el-input placeholder="" v-model="receive.count" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="支付类型">
                            <el-input placeholder="" v-model="receive.payType" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="handleReceiveOk" size="small">确定</el-button>
                <el-button @click="dialogTableReceive = false" size="small">取消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Util from '../../../../js/Util';
import UrlUtils from '../../../../js/UrlUtils';

export default {
    name: 'MemberOrder',
    props: ['form'],
    data() {
        return {
            order: {
                deptCode: this.form.deptCode,
                search: '',
                bizCode: this.form.code,
                startTime: '',
                endTime: '',
                type: '',
                code: '',
                page: 1,
                limit: Util.Limit,
            },
            dialogTableVisible: false,
            dialogTableRefund: false,
            dialogTableReceive: false,

            refund: {
                surplusCount: '',
                count: '',
                money: '',
                code: '',
            },
            receive: {
                surplusCount: '',
                count: '',
                payType: '预支付',
            },
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            loading: false,
            tableData: [],
            historyData: [],
            url: {
                page: '/reserve/order/page',
                delete: '/reserve/order/delete',
                historyPage: '/reserve/count/page',
                refund: '/reserve/order/patchExtend',
            },
        };
    },
    mounted() {
        this.handleQuery();
    },
    filters: {
        type(type) {
            if (typeof type == 'undefined') {
                return '';
            }
            if (type == '0') {
                return '标品';
            } else if (type == '1') {
                return '非标品';
            }
        },
        money(price) {
            return (price / 10000).toFixed(2);
        },
    },
    // computed:{
    //     refundMoney() {
    //         let _this=this
    //         return _this.refund.money=_this.refund.price*_this.refund.count/10000
    //     },
    // },
    methods: {
        handleQuery() {
            const _this = this;
            const _params = { params: _this.order };
            Util.queryTable(_this, _this.url.page, _params);
        },
        handleDelete(row) {
            UrlUtils.DeleteRemote(this, this.url.delete, row.code);
        },
        handleHistory(row) {
            const _this = this;
            _this.dialogTableVisible = true;
            const _params = {
                params: { bizCode: row.code },
            };
            Util.queryTable(_this, _this.url.historyPage, _params, (data) => {
                _this.historyData = data.data;
            });
        },
        handleRefund(row) {
            const _this = this;
            _this.dialogTableRefund = true;
            _this.refund = {
                surplusCount: row.surplusCount,
                code: row.code,
                price: row.price,
            };
        },
        handleReceive(row) {
            const _this = this;
            _this.dialogTableReceive = true;

            _this.receive = {
                surplusCount: row.surplusCount,
                code: row.code,
                payType: '预支付',
            };
        },
        handleRefundOk() {
            const _this = this;

            _this.dialogTableRefund = false;
            const _params = {
                type: 1,
                code: _this.refund.code,
                count: _this.refund.count,
            };
            if (_this.refund.surplusCount < 0) {
                this.$message.error('剩余数量不能少于0');
                return;
            } else {
                UrlUtils.PatchRemote(_this, _this.url.refund, _params);
            }
        },
        handleReceiveOk() {
            const _this = this;
            _this.dialogTableReceive = false;
            const _params = {
                type: 1,
                code: _this.receive.code,
                count: _this.receive.count,
            };
            if (_this.receive.surplusCount < 0) {
                _this.$message.error('剩余数量不能少于0');
                return;
            }
            UrlUtils.PatchRemote(_this, _this.url.refund, _params);
        },
        handleCreate() {
            const row = {
                deptCode: this.form.deptCode,
                deptName: this.form.deptName,
                bizCode: this.form.code,
            };
            Util.nameJump(this, 'menu.purchase.memberOrder.create', ['采购管理', '会员订购', '订购订单'], {
                form: row,
                deptCode: row.deptCode,
            });
        },
        // handleOrderMember() {
        //     this.$router.push({name: "menu.purchase.member.order"});
        // },
        handleExport() {
            UrlUtils.Export(this, '会员订购', '/reserve/order/export', this.form);
        },
    },
};
</script>

<style scoped>
.Member .el-form-item {
    margin-bottom: 0;
}
</style>
